import { ChakraProvider } from "@chakra-ui/react";
import Slider from "./Pages/Slider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Mnx from "./Pages/Mnx";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Slider />} />
          <Route exact path="/mnx" element={<Mnx />} />
          <Route exact path="/*" element={<Slider />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
