import { Flex, Center, Text, Box, Link } from "@chakra-ui/react";
import "../assets/css/linktree.css";

export default function Mnx() {
  return (
    <Flex
      width={"100vw"}
      height={"100vh"}
      alignContent={"center"}
      justifyContent={"center"}
    >
      <Center>
        <Box>
          <Text fontSize={"xl"} mb="10px">
            <Link href="../">
              <b>FRIPERIE MINIMAXXX</b>
            </Link>
          </Text>
          <Text fontSize={"xl"} mt="10px">
            <Link href="https://studiomnx.com/links">
              <b>STUDIO MNX</b>
            </Link>
          </Text>
        </Box>
      </Center>
    </Flex>
  );
}
