import {
  Image,
  Center,
  List,
  ListItem,
  Link,
  Text,
  Box,
  // useDisclosure,
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalBody,
} from "@chakra-ui/react";
// import { AiFillFacebook } from "react-icons/ai";
// import { AiFillInstagram } from "react-icons/ai";
// import { AiOutlineMinus } from "react-icons/ai";
import Logo from "../assets/img/LOGO_MINIMAXXX.png";
import "../assets/css/linktree.css";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";

export default function Linktree() {
  const [phoneType, setPhoneType] = useState(0);
  // const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setPhoneType("Android");
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setPhoneType("iOS");
    }
  }, []);

  return (
    <div className="linktree">
      <div className="header">
        <Center mt="13%">
          <Image src={Logo} boxSize="65%" />
        </Center>
      </div>

      <div className="links">
        <Center>
          <List spacing={1} mt="10%">
            {/* <ListItem>
              <Text fontSize={"xl"} onClick={onOpen}>
                <ArrowForwardIcon />
                <b>NEXT EVENT</b>
                <Center>
                  <Modal
                    isCentered
                    onClose={onClose}
                    isOpen={isOpen}
                    motionPreset="slideInBottom"
                    size="xs"
                  >
                    <ModalOverlay />
                    <ModalContent maxWidth="200px">
                      <ModalBody>
                        <Center>
                          <Link href="https://www.instagram.com/p/CkeLXfLIFx2/?igshid=MDJmNzVkMjY=">
                            <AiFillInstagram size={60} />
                          </Link>
                          <AiOutlineMinus size={45} />
                          <Link href="https://fb.me/e/27HLnJC8R">
                            <AiFillFacebook size={60} />
                          </Link>
                        </Center>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </Center>
              </Text>
            </ListItem> */}
            <ListItem>
              <Text fontSize={"xl"}>
                <Link href="https://www.minimaxxx.com/">
                  <ArrowForwardIcon />
                  <b>WWW.MINIMAXXX.COM</b>
                </Link>
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize={"xl"}>
                <Link href="https://www.instagram.com/minimax.xx/?hl=fr">
                  <ArrowForwardIcon />
                  <b>INSTAGRAM</b>
                </Link>
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize={"xl"}>
                <Link href="https://www.facebook.com/minimaxxx/">
                  <ArrowForwardIcon />
                  <b>FACEBOOK</b>
                </Link>
              </Text>
            </ListItem>
            {/* You can also use custom icons from react-icons */}
            <ListItem>
              <Text fontSize={"xl"}>
                <Link href="https://www.tiktok.com/@minimax.xx">
                  <ArrowForwardIcon />
                  <b>TIKTOK</b>
                </Link>
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize={"xl"}>
                <Link
                  href={
                    phoneType === "Android"
                      ? "https://play.google.com/store/apps/details?id=com.artivive&hl=fr&gl=US"
                      : "https://apps.apple.com/us/app/artivive/id1188737494"
                  }
                >
                  <Box>
                    <ArrowForwardIcon />
                    <b>DOWNLOAD ARTIVIVE EXPERIENCE</b> !
                    <Center>
                      <Box fontSize={12}>
                        TO SEE THE AUGMENTED REALITY POSTER
                      </Box>
                    </Center>
                  </Box>
                </Link>
              </Text>
            </ListItem>
          </List>
        </Center>
      </div>
    </div>
  );
}
