import { Image, Box } from "@chakra-ui/react";
import Slider from "react-slick";
import Couple1 from "../assets/img/couple1.png";
import Couple2 from "../assets/img/couple2.png";
import Couple3 from "../assets/img/couple3.png";
import Couple4 from "../assets/img/couple4.png";
import Couple5 from "../assets/img/couple5.png";
import Couple6 from "../assets/img/couple6.png";
import Couple7 from "../assets/img/couple7.png";
import Couple8 from "../assets/img/couple8.png";
import Couple9 from "../assets/img/couple9.png";
import Couple10 from "../assets/img/couple10.png";
import Couple11 from "../assets/img/couple11.png";
import Couple12 from "../assets/img/couple12.png";
import Couple13 from "../assets/img/couple13.png";
import Couple14 from "../assets/img/couple14.png";
import Couple15 from "../assets/img/couple15.png";
import Couple16 from "../assets/img/couple16.png";
import Couple17 from "../assets/img/couple17.png";
import Couple18 from "../assets/img/couple18.png";
import Couple19 from "../assets/img/couple19.png";
import Couple20 from "../assets/img/couple20.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Component } from "react";
import Linktree from "./Linktree";

export default class CenterMode extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: false,
    };
    return (
      <div>
        <Linktree />
        <Box position="absolute" top="80%" width="100%" zIndex="-1">
          <Slider {...settings} className="slider--image">
            <div>
              <Image src={Couple1} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple2} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple3} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple4} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple5} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple6} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple7} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple8} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple9} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple10} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple11} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple12} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple13} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple14} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple15} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple16} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple17} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple18} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple19} maxWidth="200%" height="500px" />
            </div>
            <div>
              <Image src={Couple20} maxWidth="200%" height="500px" />
            </div>
          </Slider>
        </Box>
      </div>
    );
  }
}
